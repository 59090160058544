import { Button, Typography } from '@memoresa/ui-components';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { GetServerSideProps } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import qs from 'query-string';
import { useMemo } from 'react';
import { isMobileSafari, isSafari } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ProductCard } from '../../components/landing/ProductCard';
import { ImagesService } from '../../services/images/ImagesService';
import { calcPriceParsed } from '../../utils/functions/calcPriceParsed';
import { checkApiStatus } from '../../utils/functions/checkApiStatus';
import { useGetSessionStorage } from '../../utils/hooks/useGetSessionStorage';
import { useSetLocale } from '../../utils/hooks/useSetLocale';
import { ProductDetailsLanding } from '../../utils/interfaces/ProductDetails';

const LandingPage = ({ products }: { products: ProductDetailsLanding[] }) => {
  useSetLocale();
  useGetSessionStorage();
  const router = useRouter();
  const { m_campaign } = router.query;
  const { t } = useTranslation();
  const methods = useForm({ mode: 'onChange' });
  const mainProduct = useMemo(() => {
    if (!products) return null;
    return products[0];
  }, [products]);

  const { data: image } = useQuery(['mainProduct', mainProduct], () =>
    ImagesService.getProductImage({ id: mainProduct?.image })
  );
  const isA1 = useMemo(() => {
    return m_campaign && m_campaign === 'a1click' ? true : false;
  }, [m_campaign]);

  const finalProducts = useMemo(() => {
    if (!products.length) return [];
    return products.slice(1, products.length);
  }, [products]);
  return (
    <>
      <FormProvider {...methods}>
        <div className="flex flex-col w-full gap-8 h-full">
          {mainProduct && (
            <section className="grid grid-cols-1 md:grid-cols-3 h-full">
              <div className="flex flex-col items-center justify-center order-last h-full col-span-2 gap-4 mb-4 md:order-first md:mb-0 md:items-start">
                <Typography
                  className="text-xl font-semibold text-center uppercase md:text-left "
                  color="primary"
                >
                  {mainProduct.title}
                </Typography>
                <Typography color="primary" className="w-3/4">
                  {mainProduct.subTitle}
                </Typography>
                {mainProduct.description && (
                  <div
                    className={clsx(
                      'py-2 text-[18px] font-medium order-3 sm:order-none w-3/4 text-primary',
                      isSafari || isMobileSafari
                        ? 'short-description-safari-only'
                        : 'short-description'
                    )}
                  >
                    {parse(mainProduct.description)}
                  </div>
                )}
                <div className="flex gap-2">
                  <Button
                    size="large"
                    onClick={() => router.push(`/shop/${mainProduct.key}`)}
                  >
                    <span className="uppercase">
                      {t('landingPage.emergencyCardSection.action')}
                    </span>
                  </Button>
                  <div className="relative">
                    <div className="flex h-[60px] flex-col items-center justify-center px-5 py-2 border rounded-md border-primary-20">
                      <Typography className="font-semibold text-[20px]">
                        {calcPriceParsed(
                          mainProduct.priceInfo.priceInEurCents,
                          1,
                          mainProduct.priceInfo.volumeDiscountSettings
                        )}
                      </Typography>
                    </div>
                    <Typography className="font-medium text-[14px] absolute right-0 -bottom-[14px] translate-y-1/2">
                      {t('general.words.inclVat')}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="w-full h-[500px] bg-[#C2E8D7] relative order-first md:order-last">
                {image && (
                  <Image
                    src={`data:image/png;base64,${image}`}
                    layout="fill"
                    className="object-contain w-full"
                    alt={mainProduct.title}
                  />
                )}
              </div>
            </section>
          )}
          <div className="w-full h-px bg-primary-10" />
          <section className="flex flex-col gap-2">
            <Typography color="primary" className="text-xl font-semibold">
              {t('landingPage.recommendations')}
            </Typography>
            <div className="grid w-full gap-x-6 gap-y-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {finalProducts.map(product => (
                <ProductCard key={product.id} product={product} isA1={isA1} />
              ))}
            </div>
          </section>
        </div>
      </FormProvider>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  params,
  locale,
  query,
}) => {
  try {
    const queries = qs.stringify({ campaign: query.m_campaign ?? query.ref });
    const url = `${process.env.API_HOST}/shop/products${
      queries && `?${queries}`
    }`;
    const products = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Timezone: new Date().getTimezoneOffset().toString(),
        TimezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
        'Accept-Language': locale ?? 'de',
        Authorization: `Bearer ${process.env.API_TOKEN}`,
      },
    }).then(res => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error(res.status.toString());
      }
    });
    return { props: { products: products } };
  } catch (e) {
    return checkApiStatus(e.message);
  }
};

export default LandingPage;
