import { useRouter } from 'next/router';
import qs from 'query-string';
import { useEffect } from 'react';
import { isEmpty } from '../functions/isEmpty';

// ¯\_(ツ)_/¯
export const useGetSessionStorage = () => {
  const router = useRouter();
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      (!!window.sessionStorage.getItem('source') ||
        !!window.sessionStorage.getItem('campaign') ||
        !!window.sessionStorage.getItem('ref'))
    ) {
      const queries = {
        ...(window.sessionStorage.getItem('source') &&
          !router.query?.m_src && {
            m_src: window.sessionStorage.getItem('source'),
          }),
        ...(window.sessionStorage.getItem('campaign') &&
          !router.query?.m_campaign && {
            m_campaign: window.sessionStorage.getItem('campaign'),
          }),
        ...(window.sessionStorage.getItem('ref') &&
          !router.query?.ref && {
            ref: window.sessionStorage.getItem('ref'),
          }),
      };
      const prevQueries = { ...router.query };
      delete prevQueries!.slug;

      if (router.isReady) {
        router.replace({
          pathname: router.asPath.includes('?')
            ? router.asPath.split('?')[0]
            : router.asPath,
          ...(!isEmpty(queries)
            ? {
                query: qs.stringify(queries),
              }
            : { query: prevQueries }),
        });
      }
    }
  }, [router.asPath]); //eslint-disable-line
};
