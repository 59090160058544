import { Typography } from '@memoresa/ui-components';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ImagesService } from '../../services/images/ImagesService';
import { calcPriceParsed } from '../../utils/functions/calcPriceParsed';
import { ProductDetailsLanding } from '../../utils/interfaces/ProductDetails';

export const ProductCard: React.FC<{
  product: ProductDetailsLanding;
  isA1: boolean;
}> = ({ product, isA1 }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const CURRENT_AMOUNT = 1;
  const { data: image, isLoading: isLoadingImage } = useQuery(
    ['productLanding', product],
    () => ImagesService.getProductImage({ id: product.image })
  );

  return (
    <div
      className="flex flex-col items-center gap-2 duration-300 rounded-lg shadow-lg cursor-pointer text-primary hover:shadow-xl"
      onClick={e => {
        router.push(`/shop/${product.key}${isA1 ? '?m_campaign=a1click' : ''}`);
      }}
    >
      <div className="flex items-center justify-center w-full p-2 bg-[#C2E8D7] ">
        {isLoadingImage ? (
          <div className="bg-primary-opacity-15 animate-pulse w-[150px] h-[150px]  mx-auto" />
        ) : (
          <Image
            src={`data:image/png;base64,${image}`}
            height={150}
            width={150}
            className="object-cover mx-auto"
            alt={product.title}
          />
        )}
      </div>
      <div className="flex flex-col justify-between w-full h-full gap-4 p-4 !pb-8">
        <Typography
          className="text-base font-bold uppercase !font-heebo text-left"
          hyphens={false}
        >
          {product.title}
        </Typography>
        <div className="relative ">
          <div className="flex h-[38px] sm:h-[54px] flex-col items-center justify-center px-3 py-2 rounded-md bg-secondary-light">
            <Typography className="font-semibold text-[20px] text-secondary-contrast">
              {calcPriceParsed(
                product.priceInfo.priceInEurCents,
                CURRENT_AMOUNT,
                product.priceInfo.volumeDiscountSettings
              )}
            </Typography>
          </div>
          <Typography className="font-medium text-[14px] absolute right-0 -bottom-[20px] translate-y-1/2">
            {t('general.words.inclVat')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

{
  /* <div>
            {(!product.amountSelectorConfig.max ||
              product.amountSelectorConfig.max > 1) && (
              <AmountStepper
                size="small"
                currentAmount={currentAmount}
                onClickLess={() => {
                  if (
                    currentAmount - product.amountSelectorConfig.stepping >=
                    product.amountSelectorConfig.min
                  ) {
                    setCurrentAmount(
                      amount => amount - product.amountSelectorConfig.stepping
                    );
                  }
                }}
                onClickMore={() => {
                  if (
                    currentAmount + product.amountSelectorConfig.stepping <=
                      product.amountSelectorConfig.max ||
                    !product.amountSelectorConfig.max
                  ) {
                    setCurrentAmount(
                      amount => amount + product.amountSelectorConfig.stepping
                    );
                  }
                }}
              />
            )}
          </div> */
}
{
  /* <div className="flex justify-end w-full space-x-2">
            <div className="w-full">
              <ProductActionsButton
                className="!h-10"
                onClick={() => handleAction({ product: product, type: 'buy' })}
                disabled={!product.available}
                data-cy="direct-buy-button"
              >
                {t('general.words.buyNow')}
              </ProductActionsButton>
            </div>
            {!product.directCheckout && (
              <div className="w-full">
                <ProductActionsButton
                  className="!h-10"
                  variant="secondary"
                  onClick={() =>
                    handleAction({ product: product, type: 'addToCart' })
                  }
                  disabled={!product.available}
                  data-cy="add-to-cart-button"
                >
                  {t('general.words.addToCart')}
                </ProductActionsButton>
              </div>
            )}
          </div> */
}
{
  /* </div> */
}
{
  /* </> */
}
