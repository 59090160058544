import { RequestManagerNext } from '../requestManager/requestManagerNext';

export const ImagesService = {
  getProductImage: async ({ id }): Promise<any> => {
    const { data } = await RequestManagerNext.get(
      `/api/products/images?id=${id}`
    );
    return data;
  },
  getProductImagePreview: async ({ id, isSm }): Promise<any> => {
    const { data } = await RequestManagerNext.get(
      `/api/products/imagePreview?id=${id}&size=${isSm ? 'L' : 'M'}`
    );
    return data;
  },
};
