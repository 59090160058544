import { useRouter } from 'next/router';
import { useEffect } from 'react';
import i18n from '../../i18n';
export const useSetLocale = () => {
  const router = useRouter();
  useEffect(() => {
    const getParsedLang = lang => {
      return lang.includes('-') ? lang.split('-')[0] : lang;
    };
    if (
      typeof window !== 'undefined' &&
      (!router.locale ||
        localStorage.getItem('lang')?.split('-')[0] !== router.locale)
    ) {
      let tempLang;
      tempLang = window.navigator.language;
      const lang = getParsedLang(tempLang);
      i18n.changeLanguage(localStorage.getItem('lang')?.split('-')[0] ?? lang);
      router.replace(router.asPath, router.asPath, {
        locale: localStorage.getItem('lang')?.split('-')[0] ?? lang,
      });
    }
  }, []); //eslint-disable-line
};
